.box-container-visible {
  background-color: white;
  border: 1px solid #d4d5d9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  display: block;
  margin-bottom: 2%;
}

.box-container-invisible {
  display: none;
}

.dismiss {
  cursor: pointer;
}

.card-content {
  padding-bottom: 2%;
  display: flex;
}

.image {
  background: rgba(40, 44, 63, 0.05);
  cursor: pointer;
  margin: 0;
}

.user-image {
  height: 100px;
  max-width: 100%;
}

.user-details {
  flex: 1;
  position: relative;
  padding-left: 18px;
}

.user-details2 {
  flex: 1;
  position: relative;
  padding-left: 18px;
}

.name {
  font-size: 1.3vw;
  padding-bottom: 3px;
  font-weight: 500;
}

.data {
  margin-top: 6px;
  color: #7e808c;
  font-size: 1vw;
}

.order {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  border-top-style: dotted;
  border-top-color: #d4d5d9;
}

.card-label-sml {
  font-size: 1.1vw;
  font-weight: 550;
}

.menu {
  font-size: 15px;
  padding-right: 240px;
  color: #282c3f;
  line-height: 20px;
}
.total {
  float: right;
  position: absolute;
  top: 25px;
  right: 0;
  color: #535665;
  font-size: 1.2vw;
  color: green;
}

.button {
  display: flex;
}

.fa-check {
  color: green;
  font-size: 20px;
  padding-right: 8px;
}

.accept {
  color: green;
}

.decline {
  color: red;
}

.action-btn {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 1.2vw;
  font-weight: 600;
  margin-right: 1.5%;
  line-height: 3%;
  padding: 0 2%;
  position: relative;
}

.fa-icon {
  margin-right: 4%;
}

.track-orderid {
  font-size: 0.8vw;
  font-weight: 500;
}

.track-para {
  font-size: 1.1vw;
}

/* More Details Modal */
.order-modal-title {
  font-size: 25px;
  font-weight: 600;
}
.order-modal-subtitle {
  font-size: 20px;
  text-transform: capitalize;
  color: #a9a9a9;
}
.order-modal-price p {
  font-size: 28px;
  font-weight: 500;
}
.order-modal-price span {
  font-size: 21px;
  font-weight: 300;
  color: #a9a9a9;
}
/* .order-modal {
  width: 1000px !important;
} */
.modal-bold {
  font-weight: 500;
  color: #282c3f;
}
.ant-page-header-heading-tags span {
  font-size: 15px;
}
.ant-page-header-heading-sub-title {
  font-size: 20px;
}
.ant-page-header-heading-title {
  line-height: 40px;
}
.more-details-col div{
  margin-bottom: 4%;
}
.more-details-col div:first-child{
  text-decoration: underline;
}
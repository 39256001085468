.site-page-header {
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 0px 20px 0px #00000038;
  margin-bottom: 1%;
}

.example-link {
  line-height: 24px;
  margin-right: 16px;
}

[data-theme='compact'] .example-link {
  line-height: 20px;
}

.example-link-icon {
  margin-right: 8px;
}

[data-theme='compact'] .example-link-icon {
  width: 20px;
  height: 20px;
}

.ant-page-header-rtl .example-link {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}

.ant-page-header-rtl .example-link-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-page-header-heading-title {
  font-size: 28px;
}

.ant-page-header-heading-sub-title {
  font-size: 24px;
}
.ant-page-header-heading-tags span {
  font-size: 20px;
  padding: 2px 5px;
}
@media (max-width: 768px) {
  .image {
    flex: 100%;
    margin: 24px 0 0;
  }
}

.avatar {
  font-weight: 400;
  tab-size: 8;
  list-style: none;
  box-sizing: border-box;
  border-radius: 50%;
  transition-property: none;
  padding: 0;
  background: #fdfdfd;
  overflow: hidden;
  text-align: center;
  vertical-align: top;
  color: #4f4f4f;
  font-size: 5vh;
  line-height: 48px;
  background-color: #9ecdda;
  border: 0;
  height: 8vh;
  width: 8vh;
  margin: 0;
  align-self: center;
  margin-right: 1%;
  display: inline-block;
}

.home-panel-title {
  text-align: left;
  tab-size: 8;
  box-sizing: border-box;
  color: #2e2e2e;
  margin-bottom: 5px;
  margin-top: 0.5rem;
  font-size: 20px;
  line-height: 1.5rem;
  font-weight: bold;
  display: inline-block;
}

.home-panel-metadata {
  text-align: left;
  tab-size: 8;
  box-sizing: border-box;
  display: flex !important;
  flex-wrap: wrap !important;
  color: #707070 !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
}

.data-div {
  display: inline-block;
}

.link-div {
  float: right;
  margin-top: 1%;
  font-size: 25px;
}

.product-img {
  width: 300px;
}

.lightbox-img-thumbnail {
  margin: 0 !important;
}
.product-button {
  width: 10%;
  margin: 0 15px 25px 0;
}
.product-card-price h1 {
  font-size: 30px;
  color: #868686;
}
.product-card-price h2 {
  font-size: 25px;
  margin-top: 25px;
  margin-bottom: 0;
}
.product-card-price span {
  font-size: 20px;
  text-decoration: line-through;
  color: #868686;
}
.product-card-createdAt {
}
.product-modal {
  width: 1000px !important;
}
.product-modal-title {
  font-size: 25px;
  font-weight: 600;
}
.product-modal-subtitle {
  font-size: 23px;
  text-transform: capitalize;
  color: #a9a9a9;
}
.product-modal-price p {
  font-size: 28px;
  font-weight: 500;
}
.product-modal-price span {
  font-size: 21 px;
  font-weight: 300;
  text-decoration: line-through;
  color: #a9a9a9;
}
.ant-page-header-heading-tags span {
  font-size: 15px;
}
.ant-page-header-heading-sub-title {
  font-size: 20px;
}
.ant-page-header-heading-title {
  line-height: 40px;
}
.card-product-img {
  max-width: 100%;
  height: 22vw;
}

.margin-btm {
  margin-bottom: 3%;
}

.input-layout {
  margin-left: 10%;
  width: 22vw;
  float: right;
}

.gold-tag {
  border-color: #8b7d32;
  color: black;
}

.silver-tag {
  border-color: #888888;
  color: black;
  background: #ededed;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-left: 18%;
  cursor: pointer;
}

.btn {
  border: 1px solid rgb(218, 216, 216);
  color: gray;
  background-color: white;
  padding: 5px 15px;
  /* border-radius: 8px; */
  font-size: 15px;
  font-weight: 450;
}

.upload-btn-wrapper input[type='file'] {
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.product-card-subtitle {
  font-size: 20px;
  font-weight: 500;
}
.product-card-title {
  font-size: 20px;
  font-weight: 700;
}
.product-card-moreinfo {
  font-size: 20px;
  margin: 0 15px;
}
.ant-tabs-content-holder {
  background-color: #fafafa;
  padding: 10px 25px;
}

.gallery-image-card{
  margin-top:auto;
  margin-bottom: 50px;
  padding: 20px;
  
}
.gallery-image-card:hover{
    box-shadow: 0px 0px 20px 0px #0003;
}
.gallery-button-delete{
float: left;
margin-left: 30px;
margin-top: 10px;
margin-bottom:20px;
}
.gallery-button-select{
  float: right;
  margin-right: 30px;
  margin-top: 10px;
  margin-bottom:20px;
  }
  .carousel .slide {
    margin-top:auto !important;
    margin-bottom:auto !important;
    background: #fff !important;
  }
  .product-img-view{
    margin-top: auto;
    margin-bottom: auto;
  }
.box-container-visible {
  background-color: white;
  border: 1px solid #d4d5d9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  display: block;
}

.box-container-invisible {
  display: none;
}

.dismiss {
  cursor: pointer;
  color: white;
}

.blog-input {
  border-top: none;
  border-left: none;
  border-right: none;
}

.blog-input-textarea {
  margin-top: 5%;
  border: none;
}

.blog-icon {
  margin: 0 1%;
  font-size: 1.1vw;
}
.elmtcard {
  transform: scale(0.9);
  background-color: rgb(46, 46, 46);
  color: white;
  border-radius: 5;
  border: 1px solid;
  border-color: rgb(94, 94, 94);
}
.elmtcard:hover {
  color: rgb(169, 169, 255);
}

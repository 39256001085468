.spot-prices {
  font-size: 25px;
  margin-bottom: 0;
}
.spot-prices span {
  font-weight: 550;
}
.site-card-wrapper {
  margin-bottom: 3%;
}
.price-history {
  margin: 10px 0 5px;
}
.price-history li {
  margin-bottom: 2px;
  font-size: 20px;
}
ol {
  padding-inline-start: 13px !important;
}
.current-price {
  font-size: 25px;
}
.date {
  margin-bottom: 0;
}
.date span {
  font-weight: 600;
}
.live-price-heading,
.set-price-tag {
  margin: 10px;
  font-size: 26px;
  color: #868686;
}
.spot-prices-img {
  width: 125px;
}
.text-center {
  text-align: center;
}
.making-charges table {
  margin: auto;
}
.making-charges th {
  font-size: 24px;
  padding: 0 20px;
}
.making-charges tr {
  text-align: center;
  font-size: 20px;
}
.ant-tag {
  font-size: 20px;
}
